// Toolbar
$toolbarHeight: 50px;
$toolbarVerticalPadding: 6px;
$toolbarHorizontalPadding: $toolbarVerticalPadding * 2;
$toolbarLeftSectionWidth: 280px;
$toolbarRightSectionWidth: $toolbarLeftSectionWidth;
$toolbarZIndex: 2000;

// LeftNav
$leftNavWidth: $toolbarLeftSectionWidth;
$leftNavZIndex: $toolbarZIndex - 1;

// RightNav
$rightNavWidth: $leftNavWidth;
$rightNavZIndex: $leftNavZIndex;

/* FONTS */
$secondaryTitleFontFamily: 'Poppins', sans-serif;
$textFontFamily: 'Poppins', sans-serif;

/* FONT WEIGHTS */
$secondaryTitleFontWeight: 600;
$boldFontWeight: 600;
$regularFontWeight: 400;

/* FONT SIZES */
$fontSizeXS: 12px;
$fontSizeS: 16px;
$fontSizeM: 20px;
$fontSizeL: 24px;
$fontSizeXL: 34px;
$fontSize2XL: 45px;
$fontSize3XL: 52px;
$fontSize4XL: 95px;

/* MARGIN SIZES */
$margin2XS: 0.1em;
$marginXS: 0.2em;
$marginS: 0.5em;
$marginM: 1em;
$marginL: 1.5em;
$marginXL: 2em;
$marginXXL: 2.5em;

/* ZINDEX */
$zIndexTooltip: 1040;
$zIndexSidebar: 2000;

/* BUTTONS */
// Switch button
$btnSwitchButtonWidth: 30px;
$btnSwitchButtonHeight: $btnSwitchButtonWidth;
$btnSwitchSliderWidth: 50px;
$btnSwitchSliderHeight: 20px;
$btnSwitchWidth: max($btnSwitchSliderWidth, $btnSwitchSliderWidth);
$btnSwitchHeight: max($btnSwitchButtonHeight, $btnSwitchSliderHeight);
$btnSwitchMargin: calc(($btnSwitchButtonHeight - $btnSwitchSliderHeight) / 2);
$btnSwitchTranslation: $btnSwitchSliderWidth - $btnSwitchButtonWidth;
$btnSwitchPassiveBorder: calc($btnSwitchButtonWidth / 2);
$btnSwitchActiveBorder: calc($btnSwitchButtonWidth / 3.5);

/* MISC */
$linesBorderWidth: 2px;
$linesBorderFocusWidth: 3px;

/* BOOTSTRAP */

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// Desktop - mobile sizes
$mobileMinWidth: $screen-md-min;
$mobileMaxWidth: $screen-sm-max;