$icomoon-font-family: "woutick-icons" !default;
$icomoon-font-path: "fonts" !default;
$icon_w_icons_account: "\e900";
$icon_w_icons_add: "\e901";
$icon_w_icons_add_circle: "\e902";
$icon_w_icons_add_one: "\e903";
$icon_w_icons_adjust: "\e904";
$icon_w_icons_arrow_drag_bottomL: "\e905";
$icon_w_icons_arrow_drag_bottomR: "\e906";
$icon_w_icons_arrow_drag_topL: "\e907";
$icon_w_icons_arrow_drag_topR: "\e908";
$icon_w_icons_arrow_function_down: "\e909";
$icon_w_icons_arrow_function_left: "\e90a";
$icon_w_icons_arrow_function_right: "\e90b";
$icon_w_icons_arrow_function_up: "\e90c";
$icon_w_icons_arrow_nav_down: "\e90d";
$icon_w_icons_arrow_nav_left: "\e90e";
$icon_w_icons_arrow_nav_right: "\e90f";
$icon_w_icons_arrow_nav_up: "\e910";
$icon_w_icons_at: "\e911";
$icon_w_icons_at_Circle-path1: "\e912";
$icon_w_icons_at_Circle-path2: "\e913";
$icon_w_icons_ban: "\e914";
$icon_w_icons_basket: "\e915";
$icon_w_icons_bell: "\e916";
$icon_w_icons_bell_cancel: "\e917";
$icon_w_icons_bell_outline: "\e918";
$icon_w_icons_bell_small: "\e919";
$icon_w_icons_bolt: "\e91a";
$icon_w_icons_bolt_cricle: "\e91b";
$icon_w_icons_book: "\e91c";
$icon_w_icons_book_big: "\e91d";
$icon_w_icons_book_big_outline: "\e91e";
$icon_w_icons_book_outline: "\e91f";
$icon_w_icons_box: "\e920";
$icon_w_icons_box_office: "\e921";
$icon_w_icons_CA: "\e922";
$icon_w_icons_calendar: "\e923";
$icon_w_icons_calendar_config: "\e924";
$icon_w_icons_camera: "\e925";
$icon_w_icons_camera_off: "\e926";
$icon_w_icons_cancel: "\e927";
$icon_w_icons_cancel_circle: "\e928";
$icon_w_icons_card_list: "\e929";
$icon_w_icons_card_stack: "\e92a";
$icon_w_icons_chat: "\e92b";
$icon_w_icons_chat_outline: "\e92c";
$icon_w_icons_check: "\e92d";
$icon_w_icons_check_circle: "\e92e";
$icon_w_icons_circle_outline: "\e92f";
$icon_w_icons_clip: "\e930";
$icon_w_icons_clock-copia: "\e931";
$icon_w_icons_clock: "\e932";
$icon_w_icons_code: "\e933";
$icon_w_icons_copy: "\e934";
$icon_w_icons_cowork: "\e935";
$icon_w_icons_delete: "\e936";
$icon_w_icons_doc: "\e937";
$icon_w_icons_doc_add: "\e938";
$icon_w_icons_download: "\e939";
$icon_w_icons_drag: "\e93a";
$icon_w_icons_edit: "\e93b";
$icon_w_icons_edit_box: "\e93c";
$icon_w_icons_edit_circle: "\e93d";
$icon_w_icons_envelope: "\e93e";
$icon_w_icons_euro: "\e93f";
$icon_w_icons_euroReload: "\e940";
$icon_w_icons_exclamation_circle: "\e941";
$icon_w_icons_exclamation_warning: "\e942";
$icon_w_icons_exclamation-12: "\e943";
$icon_w_icons_exclamation-148: "\e944";
$icon_w_icons_exhibitor: "\e945";
$icon_w_icons_exit: "\e946";
$icon_w_icons_expand: "\e947";
$icon_w_icons_eye_closed: "\e948";
$icon_w_icons_eye_open: "\e949";
$icon_w_icons_face_happy: "\e94a";
$icon_w_icons_face_sad: "\e94b";
$icon_w_icons_food: "\e94c";
$icon_w_icons_gaming: "\e94d";
$icon_w_icons_handshake: "\e94e";
$icon_w_icons_heart: "\e94f";
$icon_w_icons_heart_broken: "\e950";
$icon_w_icons_helpCenter: "\e951";
$icon_w_icons_history: "\e952";
$icon_w_icons_hotel: "\e953";
$icon_w_icons_hotel_big: "\e954";
$icon_w_icons_inbox: "\e955";
$icon_w_icons_inbox_out: "\e956";
$icon_w_icons_inbox_small: "\e957";
$icon_w_icons_information: "\e958";
$icon_w_icons_information_circle: "\e959";
$icon_w_icons_insert_one: "\e95a";
$icon_w_icons_key: "\e95b";
$icon_w_icons_location_pin: "\e95c";
$icon_w_icons_location_pin_circle: "\e95d";
$icon_w_icons_location_pin_small: "\e95e";
$icon_w_icons_locationPinAdd: "\e95f";
$icon_w_icons_lock: "\e960";
$icon_w_icons_lock_open: "\e961";
$icon_w_icons_medal: "\e962";
$icon_w_icons_menu_hamburger: "\e963";
$icon_w_icons_mic: "\e964";
$icon_w_icons_news: "\e965";
$icon_w_icons_next: "\e966";
$icon_w_icons_note: "\e967";
$icon_w_icons_open_in_new: "\e968";
$icon_w_icons_options: "\e969";
$icon_w_icons_paper_plane: "\e96a";
$icon_w_icons_pause: "\e96b";
$icon_w_icons_people: "\e96c";
$icon_w_icons_people_add: "\e96d";
$icon_w_icons_people_send: "\e96e";
$icon_w_icons_phone: "\e96f";
$icon_w_icons_picture: "\e970";
$icon_w_icons_picture_add: "\e971";
$icon_w_icons_play_button: "\e972";
$icon_w_icons_play_button_disabled: "\e973";
$icon_w_icons_playButtonAdd: "\e974";
$icon_w_icons_playButtonFilled: "\e975";
$icon_w_icons_pointer: "\e976";
$icon_w_icons_qr_code: "\e977";
$icon_w_icons_Question_Circle: "\e978";
$icon_w_icons_ranking: "\e979";
$icon_w_icons_refresh: "\e97a";
$icon_w_icons_remove: "\e97b";
$icon_w_icons_remove_circle: "\e97c";
$icon_w_icons_retract: "\e97d";
$icon_w_icons_rewind: "\e97e";
$icon_w_icons_save: "\e97f";
$icon_w_icons_scan: "\e980";
$icon_w_icons_search_small: "\e981";
$icon_w_icons_selection: "\e982";
$icon_w_icons_selection_cancel: "\e983";
$icon_w_icons_settings_small: "\e984";
$icon_w_icons_share: "\e985";
$icon_w_icons_slider: "\e986";
$icon_w_icons_slider_cancel: "\e987";
$icon_w_icons_slider_checked: "\e988";
$icon_w_icons_slider_question: "\e989";
$icon_w_icons_slider_suspensive: "\e98a";
$icon_w_icons_slider_warning: "\e98b";
$icon_w_icons_social_artstation: "\e98c";
$icon_w_icons_social_deviantart: "\e98d";
$icon_w_icons_social_discord: "\e98e";
$icon_w_icons_social_facebook: "\e98f";
$icon_w_icons_social_instagram: "\e990";
$icon_w_icons_social_linkedin: "\e991";
$icon_w_icons_social_pinterest: "\e992";
$icon_w_icons_social_reddit: "\e993";
$icon_w_icons_social_spotify: "\e994";
$icon_w_icons_social_twitter: "\e995";
$icon_w_icons_social_video: "\e996";
$icon_w_icons_stack: "\e997";
$icon_w_icons_star: "\e998";
$icon_w_icons_star_big: "\e999";
$icon_w_icons_star_big_outline: "\e99a";
$icon_w_icons_star_circle: "\e99b";
$icon_w_icons_star_outline: "\e99c";
$icon_w_icons_star_podium: "\e99d";
$icon_w_icons_suitcase: "\e99e";
$icon_w_icons_suspensive: "\e99f";
$icon_w_icons_sync: "\e9a0";
$icon_w_icons_tables: "\e9a1";
$icon_w_icons_tables_config: "\e9a2";
$icon_w_icons_tag: "\e9a3";
$icon_w_icons_team: "\e9a4";
$icon_w_icons_ticket: "\e9a5";
$icon_w_icons_ticket_access: "\e9a6";
$icon_w_icons_ticket_screen: "\e9a7";
$icon_w_icons_ticket_send: "\e9a8";
$icon_w_icons_ticket_send_big-path1: "\e9a9";
$icon_w_icons_ticket_send_big-path2: "\e9aa";
$icon_w_icons_ticket_send_big-path3: "\e9ab";
$icon_w_icons_ticket_send_big-path4: "\e9ac";
$icon_w_icons_ticket_send_big-path5: "\e9ad";
$icon_w_icons_ticket_send_big-path6: "\e9ae";
$icon_w_icons_ticketAdd: "\e9af";
$icon_w_icons_ticketConfig: "\e9b0";
$icon_w_icons_tickets: "\e9b1";
$icon_w_icons_trifold: "\e9b2";
$icon_w_icons_upload: "\e9b3";
$icon_w_icons_waiter: "\e9b4";
$icon_w_icons_world: "\e9b5";
$icon_w_icons_woutick: "\e9b6";
