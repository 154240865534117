@import "variables";

.main-container {
  // position: absolute;
  // top: $toolbarHeight;
  // bottom: 0;
  // left: 0;
  // right: 0;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  padding-top: 70px;
  // animation-name: out;
  animation-duration: 0.3s;

  &.left-nav-opened {
    animation-name: inLeft;
    animation-duration: 0.3s;
    margin-left: $leftNavWidth;
  }

  &.right-nav-opened {
    animation-name: inRight;
    animation-duration: 0.3s;
    // margin-right: $rightNavWidth;
  }

  .switch-container {
    width: 1200px;
    margin: 0 auto;
  }

  &.big-container {
    .switch-container {
      width: 90%;
      margin: 0 auto;
      height: 100%;

      .case {
        height: 100%;
      }
    }
  }
}

.panel {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 30px;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 0px black;

  .panel-header {
    flex: 0 0 30px;
    display: flex;
    width: 95%;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 2px solid black;

    .panel-tabs {
      flex: 1 1 0;
      display: flex;
      // justify-content: flex-end;

      .panel-navigation {
        margin: 0 20px;
      }
    }

    .panel-buttons {
      align-self: center;
    }
  }

  .panel-body {
    flex: 1 1 0;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
.panel-flat {
  box-shadow: none;

  .panel-title {
    font-family: Poppins;
    font-size: 1em;
  }
}
.panel-flat-no-margin {
  .panel-heading {
    padding: 0;
  }

  .panel-body {
    padding: 0;
    padding-top: 15px;
  }
}

.panel-scrollable {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 30px;

  &.login {
    height: 100%;
    margin-bottom: 0;
  }
}

.ngx-charts {
  max-height: 370px;
}

@keyframes inLeft {
  0% {
    margin-left: 0px;
  }
  25% {
    margin-left: 70px;
  }
  50% {
    margin-left: 140px;
  }
  75% {
    margin-left: 210px;
  }
  100% {
    margin-left: 280px;
  }
}

@keyframes out {
  0% {
    margin-left: 280px;
    //margin-right: 280px;
  }
  25% {
    margin-left: 210px;
    //margin-right: 210px;
  }
  50% {
    margin-left: 140px;
    //margin-right: 140px;
  }
  75% {
    margin-left: 70px;
    //margin-right: 70px;
  }
  100% {
    margin-left: 0px;
    //margin-right: 0px;
  }
}

@keyframes inRight {
  0% {
    margin-right: 0px;
  }
  25% {
    margin-right: 70px;
  }
  50% {
    margin-right: 140px;
  }
  75% {
    margin-right: 210px;
  }
  100% {
    margin-right: 280px;
  }
}

@media screen and (max-width: 1920px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 1150px !important;

          .panels-container {
            grid-template-columns: auto auto auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1880px) {
  .main-container {
    &.left-nav-opened,
    &.right-nav-opened {
      &.big-container {
        .switch-container {
          .panels-container {
            grid-template-columns: auto auto auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 1150px !important;

          .panels-container {
            grid-template-columns: auto auto !important;
          }
        }
      }
      .switch-container {
        width: 800px !important;
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 1000px !important;
        }
      }
      .switch-container {
        width: 800px !important;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 850px !important;
        }
      }
    }

    .panels-container {
      grid-template-columns: auto auto auto !important;
    }

    &.left-nav-opened,
    &.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 1150px !important;

          .panels-container {
            grid-template-columns: auto auto auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .main-container {
    &.left-nav-opened {
      .switch-container {
        width: 800px !important;
      }
      &.big-container {
        .switch-container {
          width: 1150px !important;
        }
      }
    }
    &.right-nav-opened {
      .switch-container {
        width: 800px !important;
      }
      &.big-container {
        .switch-container {
          width: 1150px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 700px !important;
        }
      }
    }
    &.left-nav-opened,
    &.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      .switch-container {
        width: 400px !important;
      }
      &.big-container {
        .switch-container {
          width: 100% !important;
        }
      }
    }

    &.left-nav-opened,
    &.right-nav-opened {
      &.big-container {
        .switch-container {
          .panels-container {
            grid-template-columns: auto auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .main-container {
    &.big-container {
      .switch-container {
        width: 1000px !important;

        .panels-container {
          grid-template-columns: auto auto !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1290px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 600px !important;

          .panels-container {
            grid-template-columns: auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1235px) {
  .main-container {
    .switch-container {
      width: 800px !important;
    }
  }
}

@media screen and (max-width: 1190px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 550px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 450px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1115px) {
  .main-container {
    &.left-nav-opened,
    &.right-nav-opened {
      .switch-container {
        width: 400px !important;
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .main-container {
    &.left-nav-opened.right-nav-opened {
      &.big-container {
        .switch-container {
          width: 400px !important;
        }
      }
    }
    &.big-container {
      .switch-container {
        width: 800px !important;
      }
    }
    &.left-nav-opened,
    &.right-nav-opened {
      &.big-container {
        .switch-container {
          .panels-container {
            grid-template-columns: auto !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .main-container {
    .switch-container {
      width: 400px !important;
    }
    &.big-container {
      .switch-container {
        width: 500px !important;

        .panels-container {
          grid-template-columns: auto !important;
        }
      }
    }
  }
}
