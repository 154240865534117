// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WoutickPro-primary: mat-define-palette($mat-indigo);
$WoutickPro-accent: mat-define-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$WoutickPro-warn: mat-define-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$WoutickPro-theme: mat-define-light-theme(
  $WoutickPro-primary,
  $WoutickPro-accent,
  $WoutickPro-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($WoutickPro-theme);

@import "colors";

mat-tab-group {
  height: 100%;

  &.small {
    mat-tab-header {
      height: 40px;
    }
  }

  mat-tab-header {
    height: 100px;

    .mat-tab-labels {
      height: 100%;
      background: white;

      .mat-tab-label {
        height: 100%;
        padding: 0 10px;
      }
    }

    mat-ink-bar {
      height: 5px !important;
      background-color: $emphasis !important;
    }
  }

  .mat-tab-body-wrapper {
    height: 100%;
    // height: 90%;
    // padding-bottom: 70px;
    overflow-y: auto;

    mat-tab-body {
      background-color: $backgroundColor;
    }
  }

  .mat-tab-label-content {
    display: grid !important;
    color: $selectedText;
    font-style: italic;
    padding: 0;

    ion-label,
    ion-title {
      color: $selectedText;
      font-style: italic;
      padding: 0;
    }
  }

  .mat-tab-label-active {
    .mat-tab-label-content {
      color: $selectedText;
      font-style: normal;
      font-weight: 900;

      ion-label,
      ion-title {
        color: $selectedText;
        font-style: normal;
        font-weight: 900;
      }
    }
  }
}
